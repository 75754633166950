import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "medhat-mr-admin";
export const domainName = "medhat-ramadan.com";
export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = false;
export const isCodes = true;
export const isBtns = false;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = true;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "kashmir";
export const headerSectionColor = "medhatBlue";
export const navbarIconColor = "text-sandy-700 saturate-80 dark:text-sandy-700";
export const navbarDisclosureColor = "sandy";

export const progressBarColor = "sandy";
export const loadingBarColor = "kashmir";

export const isBunny = false;

export const isPlatformSubscription = false;
export const isTimedManualSubscription = true;

export const isPrepaidSystem = true;
export const prepaidInfoColor = "emerald";

export const autoChangeRandomNames = true;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "medhatBlue";
export const registerPageColor = "sandy";

export const isAnalytics = false;
export const isAbleChangingPassword = false;

export const isMultiAdmin = true;

export const isSubjectSubscriptionable = false;

export const isAccountCreationRequest = false;

export const showExamStatisticsTable = true;

export const isCategories = true;

// export const adminStatisticsPath = "admin-statistics";

export const hasTestimonials = true;
// export const hasReferralSystem = true;

export const isAdminToAdminNote = true;

export const isUsersWithUnpaidInvoices = true;

export const isAdminToAdminTags = true;
